.form-container {
  margin: 10px;
  box-sizing: border-box;
  padding-right: 20px;
  width: auto;
}

.long-title {
  margin-top: -30px;
}

h4 {
  text-align: center;
}

.dx-field-label {
  margin-bottom: 10px;
  float: none;
  width: 170px;
}

.dx-card {
  margin: 10px;
  width: fit-content;
}

.load-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn {
  margin-left: 5px;
  margin-top: 20px;
}

.checkBoxContainer .dx-checkbox-icon {
  margin-top: 6px;
  height: 25px;
  width: 25px;
}

.pop-up .form-btn {
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: inline-block;
}

.alert {
  margin-top: 5px;
  padding: 30px;
  background-color: #eb5246;
  border-radius: 10px;
  color: white;
  font-size: 15px;
}

.word-wrap {
  white-space: normal;
  word-wrap: break-word;
}
