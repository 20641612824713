$base-accent: #78be20;
$base-font-family: "Roboto", "RobotoFallback", "Noto Kufi Arabic", "Helvetica", "Arial", sans-serif;
$base-text-color: #fff;
$base-label-color: #fff;
$base-icon-color: rgba(255, 255, 255, 0.54);
$base-spin-icon-color: rgba(255, 255, 255, 0.54);
$base-bg: #363640;
$base-border-color: #515159;
$base-success: #8bc34a;
$base-warning: #ffc107;
$base-danger: #f44336;
$base-hover-color: #fff;
$base-hover-bg: rgba(255, 255, 255, 0.05);
$base-focus-color: rgba(0, 0, 0, 0.87);
$base-focus-bg: rgba(255, 255, 255, 0.05);
$base-link-color: #78be20;
$base-border-radius: 4px;
$button-normal-color: #fff;
$button-normal-bg: #363640;
$button-normal-hover-bg: #494956;
$button-normal-focused-bg: #494956;
$button-normal-active-bg: #7d7d92;
$button-default-color: rgba(0, 0, 0, 0.87);
$button-default-bg: #78be20;
$button-default-hover-bg: #8fdc2e;
$button-default-focused-bg: #8fdc2e;
$button-default-active-bg: #bcea83;
$button-danger-color: rgba(0, 0, 0, 0.87);
$button-danger-bg: #f44336;
$button-danger-hover-bg: #f6695f;
$button-danger-focused-bg: #f6695f;
$button-danger-active-bg: #f99b94;
$button-success-color: rgba(0, 0, 0, 0.87);
$button-success-bg: #8bc34a;
$button-success-hover-bg: #a0ce6b;
$button-success-focused-bg: #a0ce6b;
$button-success-active-bg: #bbdc95;
$list-group-color: rgba(255, 255, 255, 0.6);
$list-border-color: #515159;
$list-group-header-bg: rgba(54, 54, 64, 0);
$list-normal-color: #fff;
$list-expanded-group-color: #78be20;
$list-item-hover-bg: rgba(255, 255, 255, 0.05);
$list-item-selected-bg: rgba(110, 110, 128, 0.5);
$list-item-active-bg: #656577;
$fieldset-field-label-color: white;
$button-group-normal-selected-color: #fff;
$button-group-normal-selected-bg: rgba(255, 255, 255, 0.18);
$button-group-default-selected-color: #78be20;
$button-group-default-selected-bg: rgba(120, 190, 32, 0.22);
$button-group-danger-selected-color: #f44336;
$button-group-danger-selected-bg: rgba(244, 67, 54, 0.22);
$button-group-success-selected-color: #8bc34a;
$button-group-success-selected-bg: rgba(139, 195, 74, 0.22);
$scrollable-scroll-bg: rgba(112, 112, 133, 0.7);
$switch-bg: #9494a4;
$switch-handle-off-bg: #616173;
$switch-on-bg: rgba(120, 190, 32, 0.5);
$switch-handle-on-bg: #78be20;
$tabs-tab-color: rgba(255, 255, 255, 0.54);
$tabs-tab-selected-color: #78be20;
$tabs-selected-tab-bottom-border-color: #78be20;
$tabs-tab-bg: #2f2f38;
$tabs-hovered-tab-bg-color: #464653;
$badge-color: rgba(0, 0, 0, 0.87);
$badge-bg: #78be20;
$navbar-tab-color: #fff;
$navbar-tab-selected-color: #fff;
$navbar-tab-bg: #2f2f38;
$navbar-tab-selected-bg: #363640;
$overlay-content-bg: #363640;
$overlay-shader-bg: rgba(0, 0, 0, 0.61);
$textbox-search-icon-color: rgba(255, 255, 255, 0.54);
$texteditor-color: #fff;
$texteditor-placeholder-color: gray;
$texteditor-bg: rgba(255, 255, 255, 0.04);
$texteditor-focused-bg: rgba(255, 255, 255, 0.04);
$texteditor-border-color: rgba(255, 255, 255, 0.42);
$texteditor-hover-border-color: #fff;
$texteditor-focused-border-color: #78be20;
$texteditor-hover-bg: rgba(255, 255, 255, 0.07);
$dropdowneditor-icon-active-color: rgba(255, 255, 255, 0.54);
$calendar-header-color: white;
$calendar-cell-other-color: white;
$calendar-cell-selected-bg: #78be20;
$calendar-shevron-icon-color: #78be20;
$load-indicator-segment-bg-color: #78be20;
$treeview-focused-bg: rgba(255, 255, 255, 0.05);
$menu-color: white;
$menu-item-selected-bg: #494956;
$selectbox-list-bg: #363640;
$toolbar-bg: #363640;
$popup-title-bg: transparent;
$tileview-color: #fff;
$toast-color: rgba(255, 255, 255, 0.87);
$toast-info-bg: rgba(0, 0, 0, 0.87);
$toast-warning-bg: #ffc107;
$toast-error-bg: #f44336;
$toast-success-bg: #8bc34a;
$progressbar-bg: rgba(120, 190, 32, 0.3);
$progressbar-range-bg: #78be20;
$progressbar-label-color: #78be20;
$tooltip-color: #fff;
$tooltip-bg: black;
$material-slider-bar-bg: rgba(120, 190, 32, 0.24);
$material-slider-bg: #78be20;
$material-slider-tooltip-color: rgba(0, 0, 0, 0.87);
$gallery-indicator-bg: #363640;
$gallery-indicator-item-selected-bg: #78be20;
$gallery-indicator-focused-bg: #78be20;
$gallery-navbutton-hover-color: #78be20;
$gallery-navbutton-active-color: #78be20;
$loadpanel-content-bg: #363640;
$tagbox-tag-color: rgba(255, 255, 255, 0.6);
$tagbox-tag-bg: #525261;
$tagbox-tag-active-color: #fff;
$tagbox-tag-button-remove-bg: rgba(0, 0, 0, 0.36);
$accordion-title-color: #fff;
$accordion-item-border-color: #515159;
$accordion-icon-active-color: #fff;
$colorbox-overlay-bg: #363640;
$datagrid-columnchooser-item-color: rgba(255, 255, 255, 0.6);
$datagrid-columnchooser-font-weight: 500;
$datagrid-drag-header-border-color: rgba(120, 190, 32, 0.5);
$datagrid-selection-bg: rgba(120, 190, 32, 0.07);
$datagrid-row-selected-border-color: #515159;
$datagrid-row-selected-color: #fff;
$datagrid-row-focused-color: rgba(0, 0, 0, 0.87);
$datagrid-row-focused-bg: #6ba326;
$datagrid-menu-icon-color: rgba(255, 255, 255, 0.54);
$datagrid-cell-modified-border-color: rgba(139, 195, 74, 0.32);
$datagrid-row-invalid-faded-border-color: rgba(244, 67, 54, 0.32);
$datagrid-group-row-color: rgba(255, 255, 255, 0.54);
$datagrid-group-row-bg: #363640;
$datagrid-search-color: rgba(0, 0, 0, 0.87);
$datagrid-search-bg: #78be20;
$datagrid-row-error-color: white;
$datagrid-row-error-bg: #a83e3a;
$datagrid-focused-border-color: #6ba326;
$datagrid-editor-bg: rgba(54, 54, 64, 0);
$datagrid-row-alternation-bg: #3f3f4b;
$datagrid-summary-color: rgba(255, 255, 255, 0.7);
$pager-page-selected-color: rgba(0, 0, 0, 0.87);
$pager-page-selected-bg: #78be20;
$pivotgrid-area-color: rgba(255, 255, 255, 0.54);
$pivotgrid-totalcolor: rgba(23, 23, 27, 0.2);
$pivotgrid-grandtotalcolor: rgba(255, 255, 255, 0.05);
$pivotgrid-field-area-text-color: rgba(188, 188, 188, 0.3);
$fileuploader-filename-color: #fff;
$fileuploader-falename-status-color: white;
$fileuploader-border-color: #515159;
$scheduler-base-border-color: #515159;
$scheduler-accent-border-color: #515159;
$scheduler-appointment-text-color: rgba(0, 0, 0, 0.87);
$scheduler-appointment-base-color: #78be20;
$scheduler-appointment-start-color: rgba(0, 0, 0, 0.3);
$scheduler-workspace-focused-cell-color: rgba(120, 190, 32, 0.12);
$scheduler-first-month-border-color: #515159;
$scheduler-current-time-cell-color: #78be20;
$form-field-item-color: rgba(255, 255, 255, 0.6);
$form-group-border-color: #515159;
$filterbuilder-text-color: #fff;
$filterbuilder-text-focus-color: #fff;
$filterbuilder-plus-icon-color: rgba(139, 195, 74, 0.7);
$filterbuilder-remove-icon-color: rgba(244, 67, 54, 0.7);
$filterbuilder-group-operation-color: rgba(244, 67, 54, 0.3);
$filterbuilder-item-field-color: rgba(120, 190, 32, 0.3);
$filterbuilder-item-operator-color: rgba(139, 195, 74, 0.3);
$filterbuilder-item-value-color: rgba(81, 81, 89, 0.5);
$drawer-shader-background-color: rgba(0, 0, 0, 0.5);
