@import "../../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }

  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }

  .password-margin{
    margin-bottom: 2vh
  }
}
.logo-login {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4vh;
}

.login-header {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;

  .title {
    color: $base-accent;
    font-weight: bold;
    font-size: 30px;
    margin-top: 35px;
    /*margin: 0;*/
  }
  .form .dx-fieldset {
    display: grid;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.login-footer {
  margin-top: 15px;
  text-align: center;
}
